.wrapper {
  max-height: 60px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 700px;
  margin-top: -36px;
}

.cell {
  display: flex;
  justify-content: space-between;
  width: 85px;
  cursor: pointer;
  transition: all 0.175s;
}
.cell:hover {
  background: lightgray;
}

.btnContaner {
  width: 130px;
}