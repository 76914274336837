.icon{
  cursor: pointer;
  font-size: 25px;
}

.iconCell {
  width: 40px;
  text-align: center;
}

.row:nth-child(2n) {
  background-color: #eee;
}

.serve {
  opacity: 1;
}
.one-time {
  color: #888;
}
.one-time input{
  color: #888;
}

.no-serve {
  background-color: #bbb !important;
}
.closed {
  color: #444;
  background-color: #bbb !important;
}
.closed input{
  color: #444;
}

.filterRow {
  font-size: 10px;
}
.filterRow td {
  top: 17px;
  background: #CEA4FF;
  position: sticky;
  z-index: 2;
  border-right: none;
}

.filterRow td, .filterRow td input, .filterRow td textarea {
  height: 18px;
  min-width: 40px;
}
.filterRow .icon {
  font-size: 17px;
}