.delimiter {
    width: 100%;
    height: 0;
    background-color: transparent;
    border: 0;
    border-bottom: .08em dotted #555;
    margin: 0;
    padding: 0;
}

.estimateGroupSelect {
    width: 31.5em;
    font-size: .75em;
}

.brandCell {
    max-width: 30px;
}

.brandCellName {
    transform: rotate(90deg);
    width: 133px;
    margin-left: -50px;
    letter-spacing: 2px;
    text-align: center;
}