.ant-notification-notice {
    border-radius: 4px;
    width: 400px;
    background-color: #4D4D4D;
}


.ant-notification-notice-message {
    color: #999999 !important;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.ant-notification-notice-close {
    color: #999999 !important;
}

