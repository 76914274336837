.wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 16px;
  padding: 50px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.btnConatiner {
  margin-top: 30px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.cell {
  padding: 15px;
  border: 1px solid darkgray;
}