.option {
    font-size: 12px;
}

.black div {
    color: black !important;
}

.orange div {
    color: orange !important;
}

.red div {
    color: red !important;
}


.black {
    color: black !important;
}

.orange {
    color: orange !important;
}

.red {
    color: red !important;
}
