.substrate {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
}

.wrap {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    /* top: 4em; */
    left: 0;
    bottom: .9em;
    right: 50em;
    /* width: 380px; */
    height: 15em;

    background: linear-gradient(90deg, #b0b0b0, #cfcfcf);
    border: .1em solid #aaa;
    border-left: 0;
    border-top-right-radius: .8em;
    border-bottom-right-radius: .8em;

    padding: .5em;

    bottom: 0;
    width: 100%;

    /* transition-timing-function: ease-out; */
    transition: all .25s;
    z-index: 20;
}

.closed {
    bottom: -165px;
}


.buttonsContainer {
    margin-top: 0.2em;
}

.iconWrap {
    opacity: 0.7;
    font-size: 1.7em;
    cursor: pointer;
    position: fixed;
    top: 0.3em;
    left: 0.5em;
    z-index: 10;
    transition: all 0.3s;
}

.iconWrap:hover {
    opacity: 1;
}

.counterInfo {
    margin-top: 10px;
}

.loaderContainer {
    margin-left: 10px;
}

.counter {
    margin-left: 3px;
}

.alarmMessage {
    text-align: center;
    color: #e65c5c;
    font-size: 1.1em;
}

.helpWrapper {
    margin: 5px 0 19px 5px;
    /* width: 182px; */
    opacity: 0.8;
    font-size: 10px;
    /* width: 100%; */
}

.buttonFilter {
    position: absolute;
    top: -30px;
    background-color: #b0b0b0;
    padding: 6px 15px;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}