.wrapper {
  background: #fee591;
  padding: 15px;
  padding-bottom: 20px;
  border-radius: 5px;
  font-size: 11px;
  margin-top: 15px;
  width: 830px;
  max-width: 830px;
}

.header {
  font-size: 20px;
  margin-bottom: 15px;
}

.selectContainer {
  display: flex;
  align-items: center;
  /* width: 265px; */
  justify-content: space-between;
}
.selectLabel {
  margin-right: 7px;
}

.state {
  min-width: 180px;
}
.priority {
  min-width: 160px;
}

.row {
  display: flex;
  margin-bottom: 10px;
}
.rowLabel {
  width: 75px;
  text-align: right;
  padding-right: 7px;
  padding-top: 10px;
}
.longBlock {
  width: 100%;
}

.firstRow {
  display: flex;
  justify-content: space-between;
}

.textarea {
  width: calc(100% - 1em);
  height: 75px;
  background-color: transparent;

  border: 1px solid lightgray;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 0.5em 0.5em 0;
  background-color: white;
  border-radius: 5px;
}

.dateWrapper {
  display: flex;
  justify-content: center;
}

.dateWrapper > div > div {
  height: 39px;
  width: 80px;
  text-align: center;
  border: 1px solid hsl(0, 0%, 80%);
  background: white;
  border-radius: 5px;
}

.textInput {
  width: 168px;
  height: 25px;
  background-color: transparent;

  border: 1px solid lightgray;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
}
.longInput {
  width: -webkit-fill-available;
}

.underTextContainer {
  background: lightgoldenrodyellow;
  padding: 0 5px 5px 5px;
  position: absolute;
  opacity: 0.5;
  border-radius: 0 0 5px 5px;
}

.width_200 {
  width: 200px;
}

.width_180 {
  width: 180px;
}

.width_185 {
  width: 185px;
}

.error {
  border: 1px solid red;
  border-radius: 5px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: -15px;
}
.buttonWrapper button {
  width: 200px;
}

.buttonView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 35px;
  background: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  font-size: 20px;
  margin-left: 3px;
  cursor: pointer;
  transition: all 0.175s;
}
.buttonView:hover {
  background: lightgray;
}

.disableSelect  {
  border-radius: 5px;
  background-color: lightgray;
}