.siderClosed {
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.siderClosedTitle {
    transform: rotate(-90deg);
    position: absolute;
    top: 123px;
    left: -77px;
    white-space: nowrap;
    font-size: 23px;
    cursor: pointer;
}