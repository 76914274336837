.mainWrapper {
    max-width: 175px;
}

.fileWrapper {
    width: 100%;
}

.buttonWrapper {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.buttonWrapper > button {
    margin-bottom: 5px;
}

.childrenWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 160px;
    min-height: 60px;
    padding: 5px;
    border-radius: 5px;
    border: 2px dashed #0658c2;
}

.label {
    text-align: center;
}

.removeIcon {
    cursor: pointer;
}

.fileRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.fullWidthWrapper {
    width: 100%;
    max-width: inherit;
}
.fullWidthChildren {
    width: auto;
}

.ticketPageMod {
    position: absolute;
    height: 30px;
    top: 10px;
    left: 130px;
    width: 700px;
}
.ticketPageChildrenMod {
    min-height: 30px;
    width: 650px;
}