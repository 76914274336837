.editor {
    .ant-select-selector {
        font-size: 12px;
    }

    input {
        font-size: 11px;
    }

    textarea {
        font-size: 11px;
    }

    .filterRow {
        .ant-select-selection-overflow-item {
            font-size: 9px;
        }
    }
}