.wrapper {
  background: #d6e4ff;
  padding: 15px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 15px;
  width: 800px;
  max-width: 800px;
}

.row {
  display: flex;
  position: relative;
  margin-bottom: 5px;
}
.rowLabel {
  width: 90px;
  text-align: right;
  padding-right: 7px;
  padding-top: 5px;
}
.longBlock {
  width: 100%;
}

.textarea, .input {
  width: calc(100% - 1em);
  height: 75px;
  background-color: transparent;

  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 0.5em 0.5em 0;
  background-color: white;
  border-radius: 5px;
  transition: 0.3s all;
}
.border {
  border: 1px solid lightgrey
}

.inputHeight {
  height: 20px;
  transition: 0.3s all;
}
.fourRowsArea {
  height: 55px;
}
.nineRowsArea {
  height: 130px;
}

.mBottom25 {
  margin-bottom: 25px;
}
.bigTextArea {
  height: 275px;
}

.addDataBtn {
  font-size: 21px;
  top: 21px;
  left: 14px;
  color: green;
  cursor: pointer;
  transition: 0.3s all;
}
.addDataBtn:hover {
  color: darkgreen;
}
.addDataBtnPositionFix {
  top: 0;
  left: 90px;
}

.addDataBtnUnderTable {
  position: relative;
  display: flex;
  justify-content: end;
  flex-direction: column;
  /* margin-bottom: -21px; */
  margin-left: -22px;
  left: 3px;
}

.createEqupmentModalWrapper {
  width: 400px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonSizeWrap {
  position: absolute;
  bottom: 3px;
  width: 713px;
  background: rgba(200, 200, 200, 0.5);
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}