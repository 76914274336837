.container {
    position: fixed;
    height: 3em;
    width: 100%;

    background: linear-gradient(00deg, #cfcfcf, #b0b0b0, #b0b0b0, #cfcfcf);

    border-bottom: .1em solid #aaa;

    margin: 0;

    display: flex;
    align-items: center;

    z-index: 1;
}


.text {
    font-size: 1.1em;
    font-weight: 500;
    font-style: italic;
}

.title {
    font-size: 1.1em;
    font-weight: 500;
    font-style: italic;
    margin: 0 2.5em;
}

.spacer {
    width: 100%;
    height: 3.1em;
    margin: 0;
}

.horizontalSpacer {
    margin-left: auto;
}

.link {
    color: black;
}
.link:hover {
    text-decoration: underline;
}

.messageModalText {
    font-size: 13px;
    margin-bottom: 20px;
}
