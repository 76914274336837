.icon {
  font-size: 17px;
  margin-right: 7px;
}

.input {
  width: calc(100% - 1em);
  height: 75px;
  background-color: transparent;

  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 0 0.5em 0;
  background-color: white;
  border-radius: 5px;
}
.border {
  border: 1px solid lightgrey
}

.inputHeight {
  height: 30px;
  width: 30px;
}
.selectWidth {
  width: 180px;
}


.createEqupmentModalWrapper {
  width: 400px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label {
  width: 150px;
  text-align: right;
  padding-right: 8px;
}

.buttonWrapper {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.addButton {
  margin-left: 5px;
  margin-right: 5px;
  color: green;
  cursor: pointer;
  user-select: none;
}
.decButton {
  color: red;
  cursor: pointer;
  user-select: none;
}

.error {
  border: 1px solid red;
  border-radius: 5px;
}