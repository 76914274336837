.wrapper {
  background: #d6e4ff;
  padding: 15px;
  padding-bottom: 30px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 15px;
  width: 830px;
  max-width: 830px;
}

.header {
  font-size: 20px;
  margin-bottom: 15px;
}

.row {
  display: flex;
  margin-bottom: 15px;
}

.fileName {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 13px;
  cursor: pointer;
}
.fileNameInfo {
  margin-left: 10px;
  opacity: 0.8;
  font-style: italic;
}
.fileNameDivider {
  flex: 1;
  margin-left: 10px;
  border-bottom: 1px dashed black;
}

.fileBlock {
  display: flex;
  justify-content: space-between;
}
.fileList {
  max-height: 300px;
  width: 100%;
  overflow: auto;
  margin-right: 10px;
  padding-right: 10px;
}

.imagePreview {
  cursor: pointer;
  margin-bottom: 15px;
}
.imagePreview img {
  width: 200px;
}

.messageModalText {
  font-size: 13px;
  margin-bottom: 20px;
}