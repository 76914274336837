.container {
  display: flex;
  position: relative;
  margin-bottom: 1px;
}

.input {
  height: 38px;
  border: none;
  border-radius: 3px;
  width: 100%;
  padding: 0 38px 0 11px;
}

.input:focus {
  outline: red;
}

.remove {
  position: absolute;
  display: flex;
  right: 15px;
  align-items: center;
  width: 7px;
  border-left: 1px solid;
  padding-left: 15px;
  margin-top: 10px;
  height: 19px;
  color: #cfcfcf;
  cursor: pointer;
  transition: color 0.175s;
}

.remove:hover {
  color: #949494;
}