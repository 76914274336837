.textarea {
  width: calc(100% - 1em);
  background-color: transparent;

  border: 0;
  resize: none;
  overflow: auto;
  padding: 5px;
}

.textarea:focus {
  outline: 1px solid #4193ff;
  border-radius: 3px;
}


.textCell {
  padding: 0.5em;
  text-align: center;
}

/* .selectCell > div > div {
  background: #eee;
} */

