@use '/src/variables.scss';

.customScroll {
    @extend %styledScroll
}

.isSmallSelect {
  height: 25px;

  .ant-select-selector {
    font-size: 12px;
  }
}

.ant-layout-header {
  height: 34px;
  line-height: 34;

  ul {
    height: 34px !important;
    line-height: 34px !important;
  }
}