.listWrapper {
  overflow: auto;
  transition: 0.25s all;
  margin-right: 5px;
}

.filter_search {
  position: absolute !important;
  width: 30vw !important;
  top: 6px !important;
  left: 85px !important;
  border: 1px solid #e7e7e7 !important;
  padding: 3px !important;
  z-index: 99 !important;
}

.columnsChecks {
  display: flex;
  flex-direction: column;
  max-height: 90px;
  flex-wrap: wrap;
}

.checkbox {
  font-size: 12px;
}

.checkbox {
  padding-left: 8px;
}

.customTable {
  overflow: auto;
  transition: 0.3s all;
}

.customTable th {
  padding: 3px !important;
  font-size: 11px !important;
  text-align: center !important;
}

.customTable td {
  padding: 3px !important;
  font-size: 11px !important;
  white-space: nowrap;
  text-align: center;
}


.customTable .custom-row-hover:hover {
  background-color: red !important;
}

.customTable .ant-table-cell {
  background-color: red !important;
}

.customTable td:hover {
  cursor: pointer;
}

.paginatorWrapper {
  margin-top: -35px;
  position: absolute;
  right: 0;
  top: 72px;
  z-index: 100;
  /* width: 100%; */
}