.perPageWrapper {
  width: 80px;
}

.paginationWrapper {
  display: flex;
}

.pagesWrapper {
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: space-around;
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.link {
  color: #8ab4f8;
  transition: all 0.3s;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
  color: #6684b5
}

.spacer {
  width: 100%;
}

.allTicketsWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60px;
}