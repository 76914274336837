.textarea {
  background-color: transparent;

  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 0.5em 0.5em 0;
  background-color: #e7e7e7;
  font-size: 11px;
  /* border-radius: 0.5em; */
}

.textarea:focus {
  background-color: #eee;
}

.cellContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.important {
  background-color: #d3d3d3;
}
