.wrapper {
  display: flex;
  flex-direction: column;
}

.buttonSizeWrap {
  bottom: 3px;
  margin-top: -12px;
  background: rgba(200, 200, 200, 0.5);
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s all;
}

.bigTextArea {
  height: 275px !important;
}