.wrapper {
  background: #d6e4ff;
  padding: 15px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 15px;
  width: 800px;
  max-width: 800px;
}

.newMessage{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textInput{
  width: 750px;
  height: 20px;
  background-color: transparent;

  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
}
.selectContainer {
  width: 70px;
}
.sendBtn {
  border: none;
  background: transparent;
  font-size: 35px;
  cursor: pointer;
  color: #1890ff;
  transition: 0.3s all;
}
.sendBtn:hover{
  color:#1270c7;
}
.sendBtn:active{
  color:#082b4d;
}

.messages {
  min-height: 283px;
  max-height: 283px;
  overflow: auto;
  padding-right: 10px;
  transition: all 0.3s;
}
.longMessages {
  min-height: 500px;
  max-height: 500px;
}

.logMessage {
  opacity: 0.6;
  text-align: center;
}

.rightMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.textMessage {
  padding: 5px;
  background: white;
  border-radius: 5px;
  display: inline-block;
}
.textUnderMessage {
  opacity: 0.6;
  padding: 3px;
  display: flex;
  align-items: center;
}
.lock {
  color: red;
  margin-right: 5px;
}
.pinnedMessage {
  background-color: rgb(240, 161, 161);
}

.textMessage {
  position: relative;
  z-index: 0;
  min-width: 60px;
  transition: 0.3s all;
}
.textMessage::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  left: 7px;
  top: 7px;
  background-color: white;
  z-index: -1;
  transition: 0.3s all;
}
.pinnedMessage.textMessage::before {
  background-color: rgb(240, 161, 161);
}

.rightMessage .textMessage::before {
  right: 7px;
  left: inherit;
}

.wrapPin {
  display: flex;
}
.rightMessageWrapPin {
  flex-direction: row-reverse;
}

.messagePin {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.bigSizeBtn {
  display: flex;
  justify-content: center;
  cursor: pointer;
}