.wrapper {
  background: #cacaca;
  padding: 15px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 15px;
  width: 800px;
  max-width: 800px;
}

.header {
  font-size: 20px;
  margin-bottom: 15px;
}

.selectContainer {
  display: flex;
  align-items: center;
  width: 225px;
}
.selectLabel {
  margin-right: 12px;
}

.state {
  min-width: 180px;
}
.priority {
  min-width: 160px;
}

.row {
  display: flex;
  margin-bottom: 15px;
  position: relative;
}
.rowLabel {
  width: 90px;
  text-align: right;
  padding-right: 7px;
  padding-top: 10px;
}
.longBlock {
  width: 100%;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  margin-left: 41px;
}

.textarea {
  height: 75px;
  background-color: transparent;

  border: 1px solid lightgray;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 0.5em 0.5em 0;
  background-color: white;
  border-radius: 5px;
  transition: all 0.3s;
}
.bigTextArea {
  height: 275px;
}

.dateIcon {
  font-size: 18px;
  color: lightcoral;
  position: absolute;
  left: 7px;
  z-index: 10;
  top: 4px;
}

.dateWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.dateWrapper > div > div {
  height: 25px;
  width: 100px;
  text-align: center;
  border: 1px solid hsl(0, 0%, 80%);
  background: white;
  border-radius: 5px;
  padding-left: 20px;
}

.topRow {
  display: flex;
  margin-bottom: 5px;
}
.topRow > div {
  margin-right: 5px;
}

.textInput {
  width: 168px;
  height: 18px;
  background-color: transparent;

  border: 1px solid lightgray;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 5px;
}
.longInput {
  width: -webkit-fill-available;
}

.buttonSizeWrap {
  position: absolute;
  bottom: 3px;
  right: -1px;
  width: 713px;
  background: rgba(200, 200, 200, 0.5);
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.shopData {
  display: flex;
  align-items: center;
  padding: 3px;
  background-color: white;
  border-radius: 5px;
  width: max-content;
  font-size: 18px;
  color: #1d91ff;
  cursor: pointer;
}
.shopData span {
  padding: 0 5px;
  font-size: 12px;
  color: black;
}

.blueColor {
  color: cornflowerblue;
}

.hiderShopData {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}