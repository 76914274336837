.wrapper {
  position: absolute;
  top: 135px;
  background-color: lightgray;
  border: 1px solid gray;
  padding: 7px;
  border-radius: 5px;
  font-size: 12px;
  color: black;
  cursor: default;
  z-index: 20;
  padding-right: 25px;
}

.wrapper::before {
  content: '';
  border: 1px solid gray;
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  left: 122px;
  top: -6px;
  background-color: lightgray;
  z-index: -1;
  transition: 0.3s all;
}

.wrapper::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  left: 122px;
  top: -5px;
  background-color: lightgray;
  z-index: -1;
  transition: 0.3s all;
}

.copyIcon {
  position: absolute;
  right: 5px;
  cursor: pointer;
}