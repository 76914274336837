.container {
    position: fixed;
    height: 1.2em;
    width: calc(100% - 1em);

    background-color: #cfcfcf;

    border-bottom: .1em solid #aaa;

    /* padding: .5em; */
    padding: 4px 6px 3px 6px;
    margin: 0;

    display: flex;
    align-items: center;

    z-index: 1;
}

.spacer {
    width: 100%;
    height: 1.3em;
    margin: 0;
}

.cellsContainer {
  display: flex;
}
.cellsContainer div{
  font-size: 0.75em;
  font-weight: bold;
  margin-left: 0.3em;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6em;
}
.cellsContainer div select{
  text-transform: uppercase;
}

.infoWidth {
  width: 144px;
}