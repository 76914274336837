.wrapper {
  background: #d6e4ff;
  padding: 15px 15px 0 15px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 15px;
  width: 800px;
  max-width: 800px;
  position: relative;
}

.header {
  font-size: 20px;
  margin-bottom: 15px;
}

.row {
  display: flex;
  margin-bottom: 15px;
}

.fileName {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1px;
  font-size: 13px;
  cursor: pointer;
  width: 140px;
  max-width: 140px;
  position: absolute;
  bottom: 0;
  background-color: rgba(150, 150, 150, 0.8);
  border-radius: 3px;
  padding: 4px;
  padding-top: 0;
  backdrop-filter: blur(2px);
  font-size: 12px;
}
.fileNameInfo {
  opacity: 0.8;
  font-style: italic;
  font-size: 10px;
  margin-bottom: -5px;
}
.fileUploader {
  font-size: 10px;
  margin-bottom: -4px;
  margin-top: -3px;
}
.fileNameDivider {
  flex: 1;
  margin-left: 10px;
  border-bottom: 1px dashed black;
}
.fileNameText{
  max-width: 145px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fileBlock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.fileList {
  max-height: 300px;
  width: 100%;
  overflow: auto;
  margin-right: 10px;
  padding-right: 10px;
}

.imageWrapper {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  max-height: 130px;
  transition: 0.3s all;
}
.longList {
  max-height: 330px;
}

.imagePreview {
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
}
.imagePreview img {
  width: 150px;
  height: 120px;
  
}

.iconPreview {
  font-size: 100px;
}

.iconRemove {
  position: absolute;
  right: 0;
  background: lightgray;
  border-radius: 3px;
  height: 13px;
}

.messageModalText {
  font-size: 13px;
  margin-bottom: 20px;
}

.buttonSizeWrap {
  width: 793px;
  background: rgba(200, 200, 200, 0.5);
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}