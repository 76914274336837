.ticketsLightList tr:hover td {
  background-color: #e6f7ff !important;
}

.ant-collapse {
  background-color: #fafafa;
}

.panel .ant-collapse-header {
  padding: 10px !important;
}

.panel .ant-collapse-content-box {
  padding: 4px !important;
}

.row-gray td {
  background-color: #eee;
}

.row-font-color-red td {
  color: darkred;
}

.row-font-color-gray td {
  color: #888;
}