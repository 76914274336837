.mainWrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 3.1em - 1.3em);
  min-height: calc(100vh - 3.1em - 1.3em);
  overflow: auto;
}
.isListOpen {
  max-height: calc(100vh - 3.1em - 1.3em - 15.5em);
  min-height: calc(100vh - 3.1em - 1.3em - 15.5em);
}

.pinnedWrapper {
  flex: 0 0 auto;
  width: 100%;
  max-height: 312px;
  overflow: auto;
  background: white;
}

.listWrapper {
  flex: 1 1 auto;
  overflow: auto;
}

.empty {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  font-size: 16px;
}

.collapsed {
  max-height: 20px;
}

.full {
  max-height: calc(100vh - 3.1em - 1.3em);
}