.textarea {
  width: calc(100% - 1em);
  height: 32px;
  background-color: transparent;

  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 0.5em 0.5em 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.textarea:focus {
  background-color: rgba(255, 255, 255, .55);
}


.textCell {
  padding: 0.5em;
  text-align: center;
}

.checkBoxCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorCell {
  padding: 0;
}

/* .selectCell > div > div {
  background: #eee;
} */

.dateWrapper {
  display: flex;
  justify-content: center;
}

.dateWrapper > div > div {
  height: 39px;
  width: 80px;
  text-align: center;
  border: none;
}

.datePos {
  width: 10px;
  height: 20px;
  background: #ddbfff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterCell .dateWrapper > div > div {
  height: 20px;
}
