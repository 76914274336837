.tab {
    min-width: max-content;
    font-weight: bold;
    border-radius: .15em;
    padding: .15em 0;
    /* box-shadow: inset 0 -2px 4px 2px rgba(0, 0, 0, .15); */
}

.header {
    margin: .6em 1em 0.2em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.pins {
    position: absolute;
    width: -webkit-fill-available;
    padding: 0.3em 1em;
    margin: 0;
    margin-top: -0.15em;
    z-index: 10;
}
.pinsHeaderPlace {
    margin-top: 2.5em;
}

.collapsed {
    display: none;
}

.body {
    padding: 0 0 0.1em 0.2em;
}

.spaceAtBottom {
    margin-bottom: 1em;
}

.level-2 {
    margin-left: 3em;
}
.level-3 {
    margin-left: 6em;
}