.wrapper {
  display: flex;
  margin: 5px;
}

.editorBtn {
  border: 1px solid gray;
  border-left: none;
  padding: 0.5em;
  transition: 0.3s all;
  color: black;
}
.editorBtn:first-child {
  border-left: 1px solid gray;
  border-radius: 5px 0 0 5px;
}
.editorBtn:last-child {
  border-radius: 0 5px 5px 0;
}

.active {
  background-color: gray;
  color: white
}