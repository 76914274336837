.table {
  width: 97%;
}

.thead tr td {
  padding: 2px 5px;
}

.tbody tr {
  position: relative;
}

.tbody tr td {
  background-color: white;
}

.tbody tr:first-child td:first-child {
  border-radius: 5px 0 0 0;
}
.tbody tr:first-child td:nth-last-child(2) {
  border-radius: 0 5px 0 0;
}
.tbody tr:last-child td:nth-last-child(2) {
  border-radius: 0 0 5px 0;
}
.tbody tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

.buttonsWrapper {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

.removeRow {
  position: absolute;
  margin-left: 10px;
  font-size: 21px;
  top: 3px;
  right: -25px;
  color: lightcoral;
  cursor: pointer;
  transition: 0.3s all;
  z-index: 10;
}
.removeRow:hover {
  color: red;
}