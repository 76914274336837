.input {
  width: 100%;
  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 0.5em;
  background-color: #dee;
  border-radius: 0.5em;
}

.input:focus {
  background-color: #bee;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.date > div > div {
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: 37px;
  text-align: center;
}

.error {
  border: 1px solid red;
  border-radius: 5px;
}