//стилизованный скроллбар
%styledScroll {
    &::-webkit-scrollbar-track {
        background-color: #fafafa;
    }

    &::-webkit-scrollbar {
        width: 14px;
        height: 14px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #00000066;
        border: 3px solid #fafafa;
    }
}
