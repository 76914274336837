.wrap {
    vertical-align: initial;
}

.table {
    border-spacing: 0;
}

.textCenter {
    text-align: center;
}

.icon {
    font-size: 1.55em;
    padding: 0 2px;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.3s;
}
.icon:hover {
    opacity: 1;
}

.iconsContainer .lowIcon:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.lowIcon {
    user-select: none;
    text-align: center;
    width: 33.33%;
    opacity: 0.85;
    transition: 0.3s all;
}
.lowIcon.enable {
    opacity: 1;
}

.mainInfo {
    text-align: center;
    font-style: italic;
    font-weight: 600;
}

.disabled {
    opacity: .25;
    cursor: not-allowed;
}
.disabled:hover {
    opacity: .25;
}

.statesSelect {
    font-size: 0.75em;
    height: 3em;
}

.grayInfoCell {
    background-color: #e7e7e7;
}

.firstBlock {
    background-color: #eee;
}
.secondBlock {
    background-color: #d3d3d3;
}
.thirdBlock {
    background-color: #eee;
}