.wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 5px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.btnConatiner {
  margin-top: 30px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.cell {
  padding: 15px;
  border: 1px solid darkgray;
}

.addBtn {
  display: flex;
  margin-bottom: 5px;
}
.addBtn button {
  margin-right: 5px;
}

.tableWrapper {
  max-height: calc(100vh - 3.1em - 100px);
  min-height: calc(100vh - 3.1em - 100px);
  overflow: auto;
}