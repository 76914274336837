.selectIcon {
  margin-top: 2px;
  margin-right: 5px;
  display: block;
}

.select {
  margin-top: -6px;
  display: flex;
}

.stateRowWrapper {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.ticketSelect {
  width: 180px;
}