.button {
  background-color: #14cc8f;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  display: -ms-flexbox;
  display: flex;
  height: 25px;
  border: none;
  transition: all .3s ease;
  padding: 0 16px;
  min-width: 100px;
  color: #fff;
  font-weight: 600;
}
.button:hover {
  background-color: #12b37d;
}

.buttonText {
  font-size: 11px;
  font-family: Open Sans,sans-serif;
  margin: auto;
  transition: all .3s ease;
}


/* Модификации */

.big {
  height: 34px;
}
.big div{
  font-size: 14px;
}

.red {
  background-color: #e65c5c;
}
.red:hover {
  background-color: #cc5252;
}

.blue {
  background-color: #45cbe6;
}
.blue:hover {
  background-color: #3db4cc;
}
.disabled {
  background-color: lightgray;
  pointer-events: none;
}

.width-120 {
  padding: 0;
  width: 120px;
}