html {
  font: 12px -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

  *,
  *::before,
  *::after {
      -webkit-box-sizing: inherit;
         -moz-box-sizing: inherit;
              box-sizing: inherit;
  }

body {
  margin: 0;

  background-color: #ddd;
}

a {
  text-decoration: none;
  color: cornflowerblue;
}

#root{
  font-size: 11px;
  background-color: #ddd;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.modalLabel {
  font-size: 12px;
  margin-bottom: 10px;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #ddd;
  z-index: -1;
}

.color-red {
  color: red;
}

.italic {
  font-style: italic;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.justify-space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}

.infoWidth {
  width: 11.5rem;
}

.details_width {
  width: 21rem;
}

.complete_jobs_width {
  width: 32.17rem;
}

.technical_remark_width {
  width: 23.6rem;
}

.technical_spend_width {
  width: 24.37rem;
}

.supplied_by_client_width {
  width: 17.74rem;
}

.supplied_by_service_width {
  width: 18.03rem;
}

.left_in_shop_width {
  width: 17.84rem;
}

.uncomplete_jobs_width {
  width: 18.09rem;
}

.technical_event_archive_width {
  width: 18.09rem;
}

.technical_chat_width {
  width: 18.09rem;
}
