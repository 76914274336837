.table {
    border-collapse: collapse;
}
.table thead {
    font-weight: bold;
}
.tableBorders {
    border: 1px solid gray;
}

.tableBorders td {
    border: 1px solid gray;
    padding: 0;
}

.headerCell{
    text-align: center;
}
.sortCell {
    min-width: 40px;
}

.stickyTable {
    border-collapse: separate;
    border-spacing: 0;
    border-top: none;
    border-left: 1px solid #edeff2;
}
.stickyTable thead td {
    top: 0;
    background: white;
    position: sticky;
    z-index: 2;
    border-right: none;
}
.stickyTable tbody tr:first-child td {
    border-top: none;
}
.stickyTable tbody td {
    border: none;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
}
.stickyTable tbody tr:last-child {
    border-bottom: 1px solid gray;
}

.arrow {
    display: inline-block;
    position: absolute;
    transition: all 0.3s;
    transform: rotate(0);
}
.rotate {
    transform: rotate(180deg);
}

.icon{
  cursor: pointer;
}