.loaderWrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color:rgba(0, 0, 0, 0.5);
}

.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.ldsDualRing::after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid black;
  border-color: black transparent black transparent;
  animation: ldsDualRing 1.2s linear infinite;
}

@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.small .ldsDualRing {
  width: 15px;
  height: 15px;
}
.small .ldsDualRing::after {
  width: 14px;
  height: 14px;
  margin: 0;
  border-width: 3px;
}